import Header from '../../components/Header/Header';
import Register from '../../components/Register/Register';
import Footer from '../../components/Footer/Footer';
const Registering = () => {
    return (
        <>
        <Header />
        <Register />
        <Footer />
        </>
    )
}
export default Registering;