import Header from '../../components/Header/Header';
import Mint from '../../components/Mint/Mint';
const Minting = () => {
    return (
        <>
        <Header />
        <Mint />
        </>
    )
}
export default Minting;